export enum StandardClass {
  SDG = 'SDG',
  IRIS = 'IRIS+',
  GRI = 'GRI',
  SFDR = 'EU SFDR',
  ISSB = 'ISSB',
}

export type Standard = {
  id: number
  standardClass: string
  majorCode: string | undefined
  fullCode: string | undefined
  description: string | undefined
  link: string | undefined
  colour: string | undefined
}
