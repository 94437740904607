import styled from 'styled-components'

// CSS variable component-color is used here to separate the component colour
// from the text colour. If fill is true, the text must become white otherwise
// it matches the component colour and is not visible. Previous to component-color
// we were using currentColor hence it being connected to the color property
export const BadgeWrapper = styled.div<{
  $hasFill: boolean | undefined
  $hasBorder: boolean | undefined
  $isClickable: boolean | undefined
  $isActive: boolean | undefined
}>`
  --active-color: ${({ theme, $isActive }) =>
    $isActive ? 'var(--component-color)' : theme.colors.architecture6};
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.border.radiusExtraLarge};
  padding: ${({ $hasBorder }) => ($hasBorder ? '1px 7px;' : '2px 8px;')};
  background: ${({ $hasFill: hasFill }) => (hasFill ? 'var(--active-color)' : 'none')};
  border: ${({ theme, $hasBorder }) =>
    $hasBorder ? `${theme.border.width} solid var(--active-color)` : 'none'};
  color: ${({ $hasFill: hasFill }) => (hasFill ? 'white' : 'var(--active-color)')};
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;

  &:hover {
    cursor: ${({ $isClickable: isClickable }) => (isClickable ? 'pointer' : 'default')};
    background: ${({ $hasFill: hasFill }) => (hasFill ? 'var(--component-color)' : 'none')};
    border: ${({ theme, $hasBorder }) =>
      $hasBorder ? `${theme.border.width} solid var(--component-color)` : 'none'};
    color: ${({ $hasFill: hasFill }) => (hasFill ? 'white' : 'var(--component-color)')};
  }
`
