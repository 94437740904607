import styled from 'styled-components'

export const ColumnTitleWithSortContainer = styled.div<{ $align: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $align }) => ($align === 'left' ? 'space-between' : 'flex-end')};
`

export const TitleWithTooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.25rem;
`

export const TitleText = styled.span<{ $preventLineBreak: boolean }>`
  margin-right: 0.5rem;
  white-space: ${({ $preventLineBreak }) => ($preventLineBreak ? 'nowrap' : 'normal')};
`
