import { Standard, StandardClass } from '@netpurpose/types'

const sdgInfo: (Omit<Standard, 'fullCode'> & { name: string })[] = [
  {
    id: 1,
    majorCode: '1',
    standardClass: StandardClass.SDG,
    name: 'No Poverty',
    description: 'End poverty in all its forms everywhere.',
    colour: '#E5243B',
    link: 'https://sdgs.un.org/goals/goal1',
  },
  {
    id: 1,
    majorCode: '2',
    standardClass: StandardClass.SDG,
    name: 'Zero Hunger',
    description:
      'End hunger, achieve food security and improved nutrition and promote sustainable agriculture.',
    colour: '#DDA63A',
    link: 'https://sdgs.un.org/goals/goal2',
  },
  {
    id: 3,
    majorCode: '3',
    standardClass: StandardClass.SDG,
    name: 'Good Health and Well-being',
    description: 'Ensure healthy lives and promote well-being for all at all ages.',
    colour: '#4C9F38',
    link: 'https://sdgs.un.org/goals/goal3',
  },
  {
    id: 4,
    majorCode: '4',
    standardClass: StandardClass.SDG,
    name: 'Quality Education',
    description:
      'Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.',
    colour: '#C5192D',
    link: 'https://sdgs.un.org/goals/goal4',
  },
  {
    id: 5,
    majorCode: '5',
    standardClass: StandardClass.SDG,
    name: 'Gender Equality',
    description: 'Achieve gender equality and empower all women and girls.',
    colour: '#FF3A21',
    link: 'https://sdgs.un.org/goals/goal5',
  },
  {
    id: 6,
    majorCode: '6',
    standardClass: StandardClass.SDG,
    name: 'Clean Water and Sanitation',
    description: 'Ensure availability and sustainable management of water and sanitation for all.',
    colour: '#26BDE2',
    link: 'https://sdgs.un.org/goals/goal6',
  },
  {
    id: 7,
    majorCode: '7',
    standardClass: StandardClass.SDG,
    name: 'Affordable and Clean Energy',
    description: 'Ensure access to affordable, reliable, sustainable and modern energy for all.',
    colour: '#FCC30B',
    link: 'https://sdgs.un.org/goals/goal7',
  },
  {
    id: 8,
    majorCode: '8',
    standardClass: StandardClass.SDG,
    name: 'Decent Work and Economic Growth',
    description:
      'Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.',
    colour: '#A21942',
    link: 'https://sdgs.un.org/goals/goal8',
  },
  {
    id: 9,
    majorCode: '9',
    standardClass: StandardClass.SDG,
    name: 'Industry, Innovation and Infrastructure',
    description:
      'Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.',
    colour: '#FD6925',
    link: 'https://sdgs.un.org/goals/goal9',
  },
  {
    id: 10,
    majorCode: '10',
    standardClass: StandardClass.SDG,
    name: 'Reduced Inequalities',
    description: 'Reduce inequality within and among countries.',
    colour: '#DD1367',
    link: 'https://sdgs.un.org/goals/goal10',
  },
  {
    id: 11,
    majorCode: '11',
    standardClass: StandardClass.SDG,
    name: 'Sustainable Cities and Communities',
    description: 'Make cities and human settlements inclusive, safe, resilient and sustainable.',
    colour: '#FD9D24',
    link: 'https://sdgs.un.org/goals/goal11',
  },
  {
    id: 12,
    majorCode: '12',
    standardClass: StandardClass.SDG,
    name: 'Responsible Consumption and Production',
    description: 'Ensure sustainable consumption and production patterns.',
    colour: '#BF8B2E',
    link: 'https://sdgs.un.org/goals/goal12',
  },
  {
    id: 13,
    majorCode: '13',
    standardClass: StandardClass.SDG,
    name: 'Climate Action',
    description: 'Take urgent action to combat climate change and its impacts.',
    colour: '#3F7E44',
    link: 'https://sdgs.un.org/goals/goal13',
  },
  {
    id: 14,
    majorCode: '14',
    standardClass: StandardClass.SDG,
    name: 'Life Below Water',
    description:
      'Conserve and sustainably use the oceans, seas and marine resources for sustainable development.',
    colour: '#0A97D9',
    link: 'https://sdgs.un.org/goals/goal14',
  },
  {
    id: 15,
    majorCode: '15',
    standardClass: StandardClass.SDG,
    name: 'Life on Land',
    description:
      'Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.',
    colour: '#56C02B',
    link: 'https://sdgs.un.org/goals/goal15',
  },
  {
    id: 16,
    majorCode: '16',
    standardClass: StandardClass.SDG,
    name: 'Peace, Justice and Strong Institutions',
    description:
      'Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.',
    colour: '#00689D',
    link: 'https://sdgs.un.org/goals/goal16',
  },
  {
    id: 17,
    majorCode: '17',
    standardClass: StandardClass.SDG,
    name: 'Partnerships for the Goals',
    description:
      'Strengthen the means of implementation and revitalize the Global Partnership for Sustainable Development.',
    colour: '#19486A',
    link: 'https://sdgs.un.org/goals/goal17',
  },
]

export const getSDGInfo = (sdgMajorCode?: string | null) =>
  sdgInfo.find((sdg) => sdg.majorCode === sdgMajorCode)
