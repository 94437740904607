import styled from 'styled-components'
import { Standard } from '@netpurpose/types'

export const BadgeWrapper = styled.div<{ $standardColour: Standard['colour']; $isLink?: boolean }>`
  --component-color: ${({ $standardColour, theme }) =>
    $standardColour || theme.colors.typeSecondary};

  ${({ $isLink, theme }) =>
    $isLink
      ? `&:hover {
          --component-color: ${theme.colors.actionLink};
        }`
      : ''}
`
