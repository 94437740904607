import styled from 'styled-components'

export const BadgesContainer = styled.div<{
  $numItems: number
  $inline?: boolean
  $noPadding?: boolean
  $withStandardClass?: boolean
}>`
  display: flex;
  flex-wrap: ${({ $inline }) => ($inline ? 'nowrap' : 'wrap')};
  gap: 5px;
  // This is fairly arbitrary, and necessary because the width property in
  // antd doesn't seem to work. Used in conjunction with the wrap property,
  // it allows the column to expand to fit two or three items on one row.
  min-width: ${({ $numItems, $withStandardClass }) =>
    `${$numItems * ($withStandardClass ? 3 : 1.5)}rem`};
  ${({ $inline, $withStandardClass }) =>
    $inline ? '' : `max-width: ${$withStandardClass ? '20rem' : '15rem'}`};
  padding: ${({ $noPadding }) => ($noPadding ? '0' : '0.25rem')} 0;
`
