import { FilterConfig } from '@netpurpose/types'
import { renderValOrDash } from '@netpurpose/utils'
import { IconWithTooltip } from '../../IconWithTooltip'
import { SortControl } from '../SortControl'
import {
  ColumnTitleWithSortContainer,
  TitleText,
  TitleWithTooltipContainer,
} from './ColumnTitleWithSort.style'

type Props<Model> = {
  title: string
  dataIndex: keyof Model
  filterConfig: FilterConfig<Model> | undefined
  tooltipText?: string
  logTooltipHoverEvent?: (tooltipName: string) => void
  align?: 'left' | 'right'
  sortIsAlphabetical?: boolean
  preventLineBreak?: boolean
}

export const ColumnTitleWithSort = <Model,>({
  title,
  dataIndex,
  filterConfig,
  tooltipText,
  logTooltipHoverEvent,
  align = 'left',
  sortIsAlphabetical = false,
  preventLineBreak = false,
}: Props<Model>) => {
  if (!filterConfig) {
    return renderValOrDash(title)
  }

  return (
    <ColumnTitleWithSortContainer $align={align}>
      <TitleWithTooltipContainer>
        <TitleText $preventLineBreak={preventLineBreak}>{renderValOrDash(title)}</TitleText>
        {tooltipText && (
          <IconWithTooltip
            icon="Info"
            alt="info"
            color="typeSecondary"
            tooltip={tooltipText}
            size="s"
            hideTooltip
            width={16}
            height={16}
            tooltipPlacement="bottom"
            onHover={() => logTooltipHoverEvent?.(title)}
          />
        )}
      </TitleWithTooltipContainer>
      <SortControl
        dataIndex={dataIndex}
        filterConfig={filterConfig}
        showSortText={false}
        sortIsAlphabetical={sortIsAlphabetical}
      />
    </ColumnTitleWithSortContainer>
  )
}
