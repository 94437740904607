import { SpinProps, TableProps } from 'antd'
import { AnyObject } from '@netpurpose/types'
import Loading from '../Loading'
import { LoadingContainer, StyledTable } from './Table.style'

const LoadingComponent = () => (
  <LoadingContainer>
    <Loading withSpinner={false} />
  </LoadingContainer>
)

const Table = <T extends AnyObject>(
  props: TableProps<T> & {
    $noBackgroundColorOnHover?: boolean
    $withBottomPadding?: boolean
    $alignItemsTop?: boolean
    $withTotalRow?: boolean
    $topBorderOnly?: boolean
  },
): JSX.Element => {
  const { loading, dataSource } = props
  const spinProps = { size: 'small' } as SpinProps
  const loadingProps = loading
    ? { locale: { emptyText: LoadingComponent }, loading: spinProps }
    : {}

  // FIXME: the scroll prop is like this because otherwise if no data exists
  // (i.e. it is loading or no data) then the component within the table has its
  // width continuously updated so the table continues to expand indefinitely
  const shouldScrollX = loading || !dataSource?.length ? undefined : true

  return (
    <StyledTable scroll={shouldScrollX ? { x: shouldScrollX } : {}} {...props} {...loadingProps} />
  )
}

export default Table
