import { FC } from 'react'
import styled from 'styled-components'
import { Standard } from '@netpurpose/types'
import { StandardBadge } from './StandardBadge'

const BadgesContainer = styled.div<{ $withPadding?: boolean }>`
  ${({ $withPadding }) => ($withPadding ? 'padding: 0.5rem 0' : '')};
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  line-height: 1rem;
`

export const BadgeWrapper = styled.div<{
  $greyText: boolean
  $inactive?: boolean
  $isLink?: boolean
}>`
  .standard-badge {
    color: ${({ $greyText, $inactive = false, theme }) => {
      if ($inactive) {
        return theme.colors.typeTertiary
      }
      return $greyText ? theme.colors.typePrimary : theme.colors.white
    }};
    &:hover {
      color: ${({ $greyText, $inactive = false, theme }) => {
        if ($inactive) {
          return theme.colors.typeTertiary
        }
        return $greyText ? theme.colors.typePrimary : theme.colors.white
      }};
    }

    ${({ $isLink, theme }) =>
      $isLink
        ? `
      &:hover {
        background: ${theme.colors.actionLink};
        color: ${theme.colors.white};
      }
    `
        : ''}
  }
`

export const MAJOR_SDG_CODES = Array.from({ length: 17 }, (_, i) => i + 1)?.map((sdgMajorCode) => ({
  label: `SDG ${sdgMajorCode}`,
  value: sdgMajorCode,
}))

export const shouldHaveGreyText = (majorCode: string | undefined) =>
  majorCode ? ['2', '6', '7', '11'].includes(majorCode) : false

export const SdgBadges: FC<{
  sdgs: Standard[] | undefined
  showAsInactive?: boolean
  withPadding?: boolean
  showLinks?: boolean
  onHover?: () => void
  CustomTooltip?: (props: { standard: Standard }) => JSX.Element
  code?: 'fullCode' | 'majorCode'
  placement?: 'bottom' | 'right'
}> = ({
  sdgs,
  showAsInactive = false,
  withPadding = true,
  showLinks = false,
  onHover = () => null,
  CustomTooltip,
  code,
  placement,
}) => {
  if (!sdgs) {
    return null
  }

  const sortedSdgs = [...sdgs].sort((a, b) =>
    a.majorCode && b.majorCode ? parseInt(a.majorCode) - parseInt(b.majorCode) : 0,
  )

  return (
    <BadgesContainer $withPadding={withPadding}>
      {sortedSdgs.map((standard) => (
        <BadgeWrapper
          key={standard.fullCode || standard.majorCode}
          $greyText={shouldHaveGreyText(standard.majorCode)}
          {...(showAsInactive ? { $inactive: showAsInactive } : {})}
          $isLink={showLinks && !!standard.link}
        >
          <StandardBadge
            standard={standard}
            withStandardClass
            withFill={!showAsInactive}
            inactive={showAsInactive}
            isLink={showLinks && !!standard.link}
            onHover={onHover}
            CustomTooltip={CustomTooltip}
            {...(code ? { code } : {})}
            {...(placement ? { placement } : {})}
          />
        </BadgeWrapper>
      ))}
    </BadgesContainer>
  )
}
